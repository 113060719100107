<template>
  <div class="content-div">
    <h1>Download Application Form </h1>
    <h2>Liquor Licence</h2>
    <div class="pdf-div">
      <div class="pdf-div-item ">
        <div class="pt-6"><h4>FEHB 106</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pt-6 ">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for New Issue of a Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item">
        <div><h4>FEHB 106A</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Renewal of a Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item">
        <div><h4>FEHB 106B</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Transfer of a Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item ">
        <div class="pb-6"><h4>FEHB 106C</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pb-6">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Amendment of a Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
    </div>
    <h2 class="mt-12">Club Liquor Licence</h2>
    <div class="pdf-div">
      <div class="pdf-div-item ">
        <div class="pt-6"><h4>FEHB 107</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pt-6 ">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for New Issue of a Club Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item">
        <div><h4>FEHB 107A</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Renewal of a Club Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item">
        <div><h4>FEHB 107B</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Transfer of a Club Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item ">
        <div class="pb-6"><h4>FEHB 107C</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pb-6">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Amendment of a Club Liquor Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
    </div>
    <h2 class="mt-12">Temporary Absence</h2>
    <div class="pdf-div">
      <div class="pdf-div-item ">
        <div class="pt-6"><h4>FEHB 239</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pt-6 ">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Temporary Absence of Liquor Licensee for a Period Not Exceeding 25% of the Number of Days of the Validity Period of the Licence</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item">
        <div><h4>FEHB 266A</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Authorisation of a Nominated Reserve Licensee</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item ">
        <div class="pb-6"><h4>FEHB 266B</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pb-6">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Declaration of the Nominated Reserve Licensee</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
    </div>
    <h2 class="mt-12 ">Nominated Reserve Licensee</h2>
    <div class="pdf-div mb-10">
      <div class="pdf-div-item ">
        <div class="pt-6"><h4>FEHB 266A</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pt-6 ">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Application for Authorisation of a Nominated Reserve Licensee</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
      <div class="pdf-div-item ">
        <div class="pb-6"><h4>FEHB 266B</h4></div>
        <div class="d-flex align-center flex-wrap t-wrap px-5 pb-6">
          <img width="56" class="mb-3 mt-3" src="../../assets/pdf.svg" />
          <h5 class="fw-400 em-18 ml-3 mt-10">
            <a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">
              Declaration of the Nominated Reserve Licensee</a></h5>
          <a class="icon-a mt-10" :href="require('../../assets/pdf/fehb106_new form_filled.pdf')" download="Dai_Man_Cheung_Nomination_of_Reserve_Licensee.pdf" ><v-icon class="ml-2 mb-3" color="primary">mdi-download</v-icon></a>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import OlsExpansion from '../../components/misc/ols-expansion'

export default {
  name: 'InForm',
  components: {
    BoldLine,
    OlsExpansion,
  },
  data: function () {
    return {
      items:[
          
      ],
      special:{
          order: 11,
          title:'I cannot use PPS for online payment recently. I have been using PPS payment in the past. Why does this happen and what can I do?',
          content:'<img :src="logo" alt="Error message in Internet Explorer" width="234" height="294">'
      },
      publicPath: process.env.BASE_URL
    }
  },
}
</script>

<style lang="scss">
p.expansion{
  strong.i{
       font-style: italic;
  }
  span{
    word-wrap: break-word;
  }
}
a{
      text-decoration: none !important;
    p{
      color:$ols-primary !important;
    }
    line-break: anywhere;
}

h2{
    font-size:1.75em;
    line-height:1.4em;
    color:$ols-primary;
    margin:0px;
}

.s-li{
    display: flex;
    align-items: flex-start;
    margin-bottom:12px;
    div:first-child{
        color: $ols-secondary;
        font-weight: bold;
        font-size:2em;
        margin:-6.5px 16px 0px 20px;
    }
}

.content-div h3{
  font-size:2.125em;
}

ul.expansion{
  margin-left:140px;
  
  li{
    margin-bottom:12px;
    p{
      margin:0px;
      span{
        word-wrap: break-word;
      }
    }
  }
}

h5 a {
  font-size:0.93em !important;
  text-decoration: underline !important;
}


img.expansion{
  margin-left:60px;
  margin-bottom:16px;
}
//md and down
@media screen and (max-width: 1263px) {
      h2{
        font-size:1.25em;
        line-height:1.425em;
        color:$ols-primary;
        margin:0px;
    }

}

</style>